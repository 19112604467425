fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 5px;
}

span {
    font-size: 15px;
}

.center {
  text-align: center;
}

.wide-area {
    margin: auto;
    width: 90%;
}

hr {
    border: 0;
    height: 2px;
    background: rgba(7,84,140,0.9);
    background-image: linear-gradient(to right, white, rgba(7,84,140,0.9), white); 
}

#modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 95%;
    max-width: 95%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    padding-top: 30px;
    pointer-events: all;
    text-align: center;
    padding-top: 2px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    overflow: scroll;
}

.report {
    border: 1px solid rgba(7,84,140,0.9);
    height: 50px;
    padding-left: 4px;
}

.report:hover {
    background:rgba(7,84,140,0.6);
    color: white;
    cursor: pointer;
}

.report button {
    color: red;
    border-color: red;
}

.report button:hover {
    background-color: lightcoral !important;
}

.report .column {
    text-overflow: ellipsis;
    overflow: hidden;
}

.filter {
    display: inline;
    margin-right: 15px;
}

.filter select {
    max-width: 200px;
    margin-left: 5px;
}

#roadhand label {
    font-size: 25px;
}

#roadhand select {
    font-size: 25px;
    height: 40px;
}

.column.short {
    width: 100px !important;
}

.column.long {
    width: 400px !important;
}

#roadhand-container {
    padding: 50px;
}

.row {
    flex-direction: row !important;
}

.inputs {
    margin-top: 25px;
}